/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                $(function() {
                    // $.scrollify({
                    //     section : ".j-slide",
                    //     interstitialSection:".j-slide--footer"
                    // });
                });
            },
            finalize: function () {
                // Toggle Menu
                var header = $('.o-header');
                header.on('click', '.o-header--toggle', function () {
                    header.toggleClass('s-menu-open');
                });
                header.find('.sub-menu').siblings('a').after('<span class="menu-slide"></span>');
                header.find('.menu-slide').on('click',function(e){
                    $(this).toggleClass('open');
                    e.preventDefault();
                    e.stopPropagation();
                    $(this).siblings('.sub-menu').stop(true, true).slideToggle(200);
                });



                


                var testimonialCarousel = $('.j-carousel--testimonials');
                if(testimonialCarousel.length > 0){
                    testimonialCarousel.slick({
                        dots: true,
                        infinite: false,
                        arrows: true,
                    });
                }


                $('.j-faqs').on('click', '.j-faqs--toggle', function () {
                    var _this = $(this);
                    _this.parents('.j-faqs--single').toggleClass('o-faqs--open').siblings().removeClass('o-faqs--open');
                });



                var carousel_forground = $('.j-background__front');
                var carousel_background = $('.j-background__back');


                var carousel_mobile_forground = $('.j-background__mobile-front');
                var carousel_mobile_background = $('.j-background__mobile-back');

                $('.j-banner--slides').slick({
                    arrows: true,
                    dots: true,
                    infinite: true,
                }).on('beforeChange',function(event, slick, currentSlide, nextSlide){
                   
                    var next = $(slick.$slides[nextSlide]).find('.o-banner--slide');

                    var background = next.data('background');
                    var mobile_background = next.data('background-mobile');

                    background = 'url(' + background + ') no-repeat bottom center';
                    mobile_background = 'url(' + mobile_background + ') no-repeat bottom center';


                    carousel_background.css( {'background' : background });
                    carousel_mobile_background.css( {'background' : mobile_background });
                    
                    carousel_mobile_forground.fadeOut('slow', function(){
                        carousel_mobile_forground.css( {'background' : mobile_background });
                        carousel_mobile_forground.show();
                    });
                    carousel_forground.fadeOut('slow', function(){
                        carousel_forground.css( {'background' : background });
                        carousel_forground.show();
                    });
                });

                $('.j-products--carousel').slick({
                    arrows: true,
                    infinite: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    responsive: [
                        {
                          breakpoint: 1200,
                          settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                          }
                        },
                        {
                          breakpoint: 900,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                          }
                        },
                        {
                          breakpoint: 700,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          }
                        },
                    ]
                });


            }
        },
        // Home page
        'page_template_homepage': {
            init: function () {
                // JavaScript to be fired on the home page

          

            },
            finalize: function () {
              
            }
        },

        // Home page
        'single_product': {
            init: function () {
                // JavaScript to be fired on the home page

                $('.j-accordion').on('click','.j-accordion--toggle',function(){
                    var _this = $(this);
                    _this.parents('.j-accordion').toggleClass('isActive').find('.j-accordion--body').slideToggle();
                });


                // $('.j-product-image').on('click','.j-product-image--thumb', function(){
                //     var _this = $(this);
                //     var newImage = _this.html();
                //     var parent = _this.parents('.j-product-image');
                //     parent.find('.j-product-image--main').html(newImage);
                // });

                $('.o-product--main').slick({
                    dots: true,
                    arrows: false,
                    customPaging: function(slider, i) { 
                      // this example would render "tabs" with titles
                      return '<img src="' + $(slider.$slides[i]).find('.o-product--main-img').attr('src') + '">';
                    },
                });

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS


            }
        },


    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

